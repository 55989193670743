<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <v-img
            src="@/assets/icono.png"
            max-height="30"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="text-h4"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      itemsuser: [
        {
          title: 'clientes',
          icon: 'mdi-account-tie',
          to: '/user/clientes',
        },
      ],
      itemscliente: [
        {
          title: 'dashboard',
          icon: 'mdi-view-dashboard',
          to: '/cliente/dashboard',
        },
        {
          title: 'ficha',
          icon: 'mdi-account-tie',
          to: '/cliente/ficha',
        },
        {
          title: 'expedientes',
          icon: 'mdi-file-cabinet',
          to: '/cliente/expedientes',
        },
        {
          title: 'agenda',
          icon: 'mdi-calendar-month',
          to: '/cliente/agenda',
        },
      ],
      itemsadmin: [
        {
          title: 'dashboard',
          icon: 'mdi-view-dashboard',
          to: '/admin/dashboard',
        },
        {
          icon: 'mdi-cog-outline',
          title: 'maestros',
          group: '/admin/maestros',
          children: [
            {
              icon: 'mdi-state-machine',
              title: 'estadoexp',
              to: 'estadoexp',
            },
            {
              icon: 'mdi-earth',
              title: 'paises',
              to: 'paises',
            },
            {
              icon: 'mdi-compass',
              title: 'provincias',
              to: 'provincias',
            },
            {
              icon: 'mdi-city',
              title: 'localidad',
              to: 'localidad',
            },
            {
              icon: 'mdi-human-male-female',
              title: 'estadociv',
              to: 'estadociv',
            },
            {
              icon: 'mdi-gavel',
              title: 'rem',
              to: 'rem',
            },
            {
              icon: 'mdi-bank',
              title: 'rf',
              to: 'rf',
            },
            {
              icon: 'mdi-forum',
              title: 'rsb',
              to: 'rsb',
            },
            {
              icon: 'mdi-elevation-rise',
              title: 'cargos',
              to: 'cargossocietarios',
            },
            {
              icon: 'mdi-alarm-plus',
              title: 'durafirmas',
              to: 'perfilduracionfirma',
            },
            {
              icon: 'mdi-clipboard-text',
              title: 'salas',
              to: 'salasfirma',
            },
            {
              icon: 'mdi-file-replace-outline',
              title: 'tipodoc',
              to: 'tipodoc',
            },
          ],
        },
        {
          icon: 'mdi-account-box-multiple-outline',
          title: 'usuarios',
          group: '/admin/usuarios',
          children: [
            {
              icon: 'mdi-account-tie',
              title: 'clientes',
              to: 'clientes',
            },
            {
              icon: 'mdi-account-tie',
              title: 'empleados',
              to: 'empleados',
            },
          ],
        },
        {
          title: 'procedimientos',
          icon: 'mdi-google-circles-extended',
          to: '/admin/procedimientos',
        },
        {
          title: 'expedientes',
          icon: 'mdi-file-cabinet',
          to: '/admin/expedientes',
        },
        {
          title: 'agenda',
          icon: 'mdi-calendar-month',
          to: '/admin/agenda',
        },
      ],
      itemsoficial: [
        {
          title: 'dashboard',
          icon: 'mdi-view-dashboard',
          to: '/oficial/dashboard',
        },
        {
          title: 'clientes',
          icon: 'mdi-account-tie',
          to: '/oficial/clientes',
        },
        {
          title: 'expedientes',
          icon: 'mdi-file-cabinet',
          to: '/oficial/expedientes',
        },
        {
          title: 'agenda',
          icon: 'mdi-calendar-month',
          to: '/oficial/agenda',
        },
      ],
      itemsnotario: [
        {
          title: 'dashboard',
          icon: 'mdi-view-dashboard',
          to: '/notario/dashboard',
        },
        {
          title: 'clientes',
          icon: 'mdi-account-tie',
          to: '/notario/clientes',
        },
        {
          title: 'expedientes',
          icon: 'mdi-file-cabinet',
          to: '/notario/expedientes',
        },
        {
          title: 'agenda',
          icon: 'mdi-calendar-month',
          to: '/notario/agenda',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        let menu = null
        switch (localStorage.getItem('role')) {
          case 'ROLE_ADMIN':
            menu = this.itemsadmin.map(this.mapItem)
            break
          case 'ROLE_CLIENTE':
            menu = this.itemscliente.map(this.mapItem)
            break
          case 'ROLE_OFICIAL':
            menu = this.itemsoficial.map(this.mapItem)
            break
          case 'ROLE_USER':
            menu = this.itemsuser.map(this.mapItem)
            break
          case 'ROLE_NOTARIO':
            menu = this.itemsnotario.map(this.mapItem)
            break
        }
        return menu
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
